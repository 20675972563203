// extracted by mini-css-extract-plugin
export var productsOverview = "starterkitProductsOverview-module--productsOverview--y3exT";
export var textSection = "starterkitProductsOverview-module--textSection--8abre";
export var productTitle = "starterkitProductsOverview-module--productTitle--OdeEq";
export var productTitleYellow = "starterkitProductsOverview-module--productTitleYellow--BJaHY";
export var productTitleGreen = "starterkitProductsOverview-module--productTitleGreen--soPpD";
export var productSubtitle = "starterkitProductsOverview-module--productSubtitle--1fGwZ";
export var yellowUnderline = "starterkitProductsOverview-module--yellowUnderline--cPCSw";
export var productDescription = "starterkitProductsOverview-module--productDescription--REAbW";
export var productSubtitleGreen = "starterkitProductsOverview-module--productSubtitleGreen--Hk6nz";
export var productImg = "starterkitProductsOverview-module--productImg--5UfJL";
export var productRow = "starterkitProductsOverview-module--productRow--+arHI";