import React, { useState, useEffect } from "react"
import Layout from "../../components/layout/layout"

import { useStaticQuery, graphql } from "gatsby"

import SingleProductHeader from "../../components/product/singleProductHeader"
import SingleProductSlogan from "../../components/product/singleProductSlogan"
import SingleProductInitialQuestion from "../../components/product/singleProductInitialQuestion"
import StarterkitProductsOverview from "../../components/product/starterkitProductsOverview"
import AllReviews from "../../components/reviews/allReviews"
import FaqSection from "../../components/product/faqSection"
import CustomersBought from "../../components/product/customersBoughtSection"
import CookieConsent from "react-cookie-consent";

import { sortImages } from '../../utils/helper'

import useIsClient from '../../useIsClient';

import Seo from "../../components/layout/seo";

export default function Starterkit() {

  const { isClient, key } = useIsClient();

  const data = useStaticQuery(graphql`
    query {
      allShopifyProduct(sort: {fields: [title]}, filter: {id: {eq: "72c83be1-b5c8-5b3d-aa9a-d4c69816c66a"}}) {
        edges {
          node {
            title
            images {
              originalSrc
              id
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                }
              }
            }
            options {
              name
              values
            }
            shopifyId
            description
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            id
            variants {
              id
            }
          }
        }
        nodes {
          variants {
            title
            shopifyId
            selectedOptions {
              name
              value
            }
            metafields {
              key
              value
            }
            price
            compareAtPrice
          }
          images {
            localFile {
              name
              childImageSharp {
                gatsbyImageData(height: 90, width: 90, placeholder: NONE, layout: FIXED)
              }
            }
          }
        }
      },
      productThumbnails: allShopifyProduct(sort: {fields: [title]}, filter: {id: {eq: "72c83be1-b5c8-5b3d-aa9a-d4c69816c66a"}}) {
        edges {
          node {
            images {
              originalSrc
              id
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 105, width: 84, placeholder: NONE)
                }
              }
            }
          }
        }
      },
      customersBought: allShopifyProduct(
        sort: {fields: [title], order: DESC}
        filter: {id: {in: ["8d651639-2db7-5e3a-a3aa-1866bb0d9f53", "46e3acb2-5819-530c-9958-2a4e07ba522e"]}}
      ) {
        edges {
          node {
            title
            images {
              originalSrc
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 650, width: 625, placeholder: NONE)
                }
              }
            }
            options {
              name
              values
            }
            shopifyId
            description
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            id
            variants {
              id
            }
            handle
          }
        }
        nodes {
          variants {
            title
            shopifyId
            selectedOptions {
              name
              value
            }
            metafields {
              key
              value
            }
            price
            compareAtPrice
          }
        }
      },
      zubehoerReview: allDropsReview(
        sort: {fields: createdAt, order: DESC}
        filter: {curated: {eq: true}, productIds: {ids: {in: ["7351700848874"]}}, rating: {gte: 1}}
      ) {
        totalCount
        sum(field: rating)
      },
      monatspaketReview: allDropsReview(
        sort: {fields: createdAt, order: DESC}
        filter: {curated: {eq: true}, productIds: {ids: {in: ["6899851854007"]}}, rating: {gte: 1}}
      ) {
        totalCount
        sum(field: rating)
      },
      allDropsReview(
        filter: {curated: {eq: true}, productIds: {ids: {in: ["7351701078250"]}}, rating: {gte: 1}}
        sort: {fields: createdAt, order: DESC}
      ) {
        edges {
          node {
            body
            createdAt
            files
            id
            name
            rating
            title
            productIds {
              ids
            }
          }
        }
        totalCount
        sum(field: rating)
      },
      allDropsQuestion(
        filter: {curated: {eq: true}, productIds: {ids: {in: ["7351701078250"]}}}
      ) {
        edges {
          node {
            body
            curated
            name
            title
            createdAt
            productQuestionAnswers {
              body
              createdAt
            }
          }
        }
      }
    }
  `)

  // reorder images
  data.allShopifyProduct.edges[0].node.images = sortImages('localFile.name', data.allShopifyProduct.edges[0].node.images);
  data.allShopifyProduct.nodes[0].images = sortImages('localFile.name', data.allShopifyProduct.nodes[0].images);
  data.productThumbnails.edges[0].node.images = sortImages('localFile.name', data.productThumbnails.edges[0].node.images);
  data.customersBought.edges[0].node.images = sortImages('localFile.name', data.customersBought.edges[0].node.images);
  data.customersBought.edges[0].node.reviewsCount = data.zubehoerReview.totalCount;
  data.customersBought.edges[0].node.reviewsRating = (data.zubehoerReview.sum/data.zubehoerReview.totalCount).toFixed(1);
  data.customersBought.edges[1].node.images = sortImages('localFile.name', data.customersBought.edges[1].node.images);
  data.customersBought.edges[1].node.reviewsCount = data.monatspaketReview.totalCount;
  data.customersBought.edges[1].node.reviewsRating = (data.monatspaketReview.sum/data.monatspaketReview.totalCount).toFixed(1);

  const [variantArrayData, setVariantArrayData] = useState(null);
  const [activeVariantKeyString, setActiveVariantKeyString] = useState(data.allShopifyProduct.edges[0].node.options[0].values[0].replace(' ', '_'));
  const [activeVariantId, setActiveVariantId] = useState(data.allShopifyProduct.nodes[0].variants[0].shopifyId);
  const [fluoridStatus, setFluoridStatus] = useState(0);

  useEffect(() => {
    var variantArray = {}
    var regex = /[+-]?\d+(\.\d+)?/g;
    data.allShopifyProduct.nodes[0].variants.forEach((variant, variantKey) => {
      variantArray[variant.selectedOptions[0].value.replace(' ', '_')] = {
        productTitle: data.allShopifyProduct.edges[0].node.title,
        title: variant.title,
        price: parseFloat(variant.price),
        compareAtPrice: variant.compareAtPrice,
        shopifyId: variant.shopifyId,
        productImg: data.allShopifyProduct.nodes[0].images[0].localFile,
        tryImg: data.tryImg,
        basePrice: variant.metafields.length > 0 ? variant.metafields[0].value.replace(',','.').match(regex).map(function(v) { return parseFloat(v); })[0] : 0
      }
    });
    setVariantArrayData(variantArray);
  }, [data]);


  function handleFluoridSelect(e) {
    setFluoridStatus(parseInt(e.currentTarget.dataset.key))
    setActiveVariantKeyString(e.currentTarget.dataset.value.replace(' ', '_'))
    setActiveVariantId(variantArrayData[e.currentTarget.dataset.value.replace(' ', '_')].shopifyId)
  }

  const productData = {
    product: data.allShopifyProduct,
    productThumbnails: data.productThumbnails,
    variants: variantArrayData,
    activeVariantKeyString: activeVariantKeyString,
    activeVariantId: activeVariantId,
    title: 'Starter Kit (6 Produkte)',
    titleAbove: 'Die 100% Plastikfreie Alternative',
    name: 'Starter Kit',
    description: <><p><span className='font-weight-bold'>Zahnpflege mit gutem Gewissen in einem Set.</span> Alles, was es für eine ganzheitliche Zahnpflege braucht, in einem Set komplett nachhaltig und plastikfrei. Diese Produkte sind enthalten:</p></>,
    keypoints: ['Zungenschaber', 'Zahnseide', 'Wechselzahnbürste', 'Reiseetui', 'Aufbewahrungstin', 'Glas mit 62 Drops'],
    keypointsShort: ['Zungenschaber', 'Zahnseide', 'Wechselzahnbürste', 'Reiseetui', 'Aufbewahrungstin', 'Glas mit 62 Drops'],
    showSelection: false,
    showDetails: true,
    sliderTitle: 'Perfektes Set zum nachhaltigen Zahnputzerlebnis'
  }

  let productIds = {
  	"ids": [
  		"7351701078250"
  	]
  }

  if ( !isClient ) return null;

  return (
    <Layout activeVariant={variantArrayData[activeVariantKeyString]} key={key}>
      <Seo title="Drops Starterkit | Natürliche Minze | Toothpaste Drops" path="/products/starterkit" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <SingleProductHeader data={productData} fluoridStatus={fluoridStatus} handleFluoridSelect={handleFluoridSelect} reviewRating={(data.allDropsReview.sum/data.allDropsReview.totalCount).toFixed(1)} reviewCount={data.allDropsReview.edges.length} />
      <SingleProductSlogan slogan={<>100% plastikfrei,<br />100% Zahnpflege</>} sloganSubtext="Für ein doppelt gutes Gewissen" />
      <SingleProductInitialQuestion question={<>Was ist alles im Starterkit<br />enthalten?</>} />
      <StarterkitProductsOverview />
      <AllReviews reviews={data.allDropsReview} questions={data.allDropsQuestion} reviewRating={(data.allDropsReview.sum/data.allDropsReview.totalCount).toFixed(1)} productIds={productIds} />
      <FaqSection />
      <CustomersBought data={data.customersBought} />
      <CookieConsent style={{ background: "#000000", color: "#ffffff", fontSize: "14px", alignItems: "center" }} contentClasses="m-2" buttonClasses="m-2" buttonStyle={{ color: "#ffffff", background: "#0275d8", fontSize: "13px" }} buttonText="OK">Zur Bereitstellung von Diensten verwenden wir Cookies. Durch die Nutzung dieser Website stimmen Sie zu.</CookieConsent>
    </Layout>
  )
}
