import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import * as styles from "./starterkitProductsOverview.module.scss"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function StarterkitProductsOverview() {

  const data = useStaticQuery(graphql`
    query {
      zungenschaber: file(relativePath: { eq: "zungenschaber.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      zahnseide: file(relativePath: { eq: "zahnseide.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      zahnbuerste: file(relativePath: { eq: "steckzahnbuerste.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      reiseetui: file(relativePath: { eq: "reiseetui.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      tin: file(relativePath: { eq: "tin.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
      dropsimglas: file(relativePath: { eq: "dropsimglas.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      },
    }
  `)

  return (
    <div className={styles.productsOverview}>
      <Container>
        <Row className={`${styles.productRow} align-items-center`}>
          <Col lg={6} className={styles.textSection}>
            <h2 className={styles.productTitle}>Bambus Wechselzahnbürste</h2>
            <p className={styles.productSubtitle}><span className={styles.yellowUnderline}>Plastikfreie</span> Wechselzahnbürste - mittel</p>
            <GatsbyImage image={data.zahnbuerste.childImageSharp.gatsbyImageData} className={`d-block d-lg-none ${styles.productImg}`} alt="Steckzahnbürste aus Bambus" />
            <p className={styles.productDescription}>Nachhaltig Lächeln. Perfekt mit unserer zweiteiligen plastikfreien Zahnbürste. Wiederverwendbarer Griff und austauschbarer Kopf, auch sehr gut für Reisen geeignet. Das passende Reiseetui ist auch enthalten.</p>
          </Col>
          <Col lg={6}>
            <GatsbyImage image={data.zahnbuerste.childImageSharp.gatsbyImageData} className={`d-none d-lg-block ${styles.productImg}`} alt="Steckzahnbürste aus Bambus" />
          </Col>
        </Row>
        <Row className={`${styles.productRow} align-items-center`}>
          <Col lg={6}>
            <GatsbyImage image={data.zungenschaber.childImageSharp.gatsbyImageData} className={`d-none d-lg-block ${styles.productImg}`} alt="Zungenschaber" />
          </Col>
          <Col lg={6} className={styles.textSection}>
            <h2><span className={styles.productTitleYellow}>Zungenschaber</span></h2>
            <p className={styles.productSubtitle}>Hygienisch aus nachhaltigem Edelstahl</p>
            <GatsbyImage image={data.zungenschaber.childImageSharp.gatsbyImageData} className={`d-block d-lg-none ${styles.productImg}`} alt="Zungenschaber" />
            <p className={styles.productDescription}>Zeig deine Zunge. Die tägliche Reinigung der Zunge ist besonders wichtig, um Entzündungen vorzubeugen und Mundgeruch zu bekämpfen.</p>
          </Col>
        </Row>
        <Row className={`${styles.productRow} align-items-center`}>
          <Col lg={6} className={styles.textSection}>
            <h2 className={styles.productTitle}>Reiseetui</h2>
            <p className={styles.productSubtitle}><span className={styles.yellowUnderline}>Perfekt</span> für unterwegs</p>
            <GatsbyImage image={data.reiseetui.childImageSharp.gatsbyImageData} className={`d-block d-lg-none ${styles.productImg}`} alt="Reiseetui aus Bambus" />
            <p className={styles.productDescription}>Wiederverwendbare Reisehülle aus nachhaltigem Bambus im schicken Design. Die perfekte Ergänzung zur Drops Wechselzahnbürste.</p>
          </Col>
          <Col lg={6}>
            <GatsbyImage image={data.reiseetui.childImageSharp.gatsbyImageData} className={`d-none d-lg-block ${styles.productImg}`} alt="Reiseetui aus Bambus" />
          </Col>
        </Row>
        <Row className={`${styles.productRow} align-items-center`}>
          <Col lg={6}>
            <GatsbyImage image={data.zahnseide.childImageSharp.gatsbyImageData} className={`d-none d-lg-block ${styles.productImg}`} alt="Zahnseide" />
          </Col>
          <Col lg={6} className={styles.textSection}>
            <h2><span className={styles.productTitleYellow}>Zahnseide</span></h2>
            <p className={styles.productSubtitle}>Aus nachhaltigem Bambus in nachfüllbarem Glas</p>
            <GatsbyImage image={data.zahnseide.childImageSharp.gatsbyImageData} className={`d-block d-lg-none ${styles.productImg}`} alt="Zahnseide" />
            <p className={styles.productDescription}>Gute Zähne sind gute Seide wert. Hochwertige Zahnseide aus nachhaltigem Bambusfarn für die tägliche Pflege der Zahnzwischenräume. Entfernt Plaque und schützt das Zahnfleisch.</p>
          </Col>
        </Row>
        <Row className={`${styles.productRow} align-items-center`}>
          <Col lg={6} className={styles.textSection}>
            <h2 className={styles.productTitle}>Reisedose</h2>
            <p className={styles.productSubtitle}><span className={styles.yellowUnderline}>Nachfüllbare</span> Dose für unterwegs</p>
            <GatsbyImage image={data.tin.childImageSharp.gatsbyImageData} className={`d-block d-lg-none ${styles.productImg}`} alt="Aufbewahrungstin zum Reisen" />
            <p className={styles.productDescription}>Auch auf Reisen dürfen die Drops nicht fehlen. Zähle deine Drops für die nächste Reise ab und nimm sie platzsparend in unserem Aufbewahrungstin mit.</p>
          </Col>
          <Col lg={6}>
            <GatsbyImage image={data.tin.childImageSharp.gatsbyImageData} className={`d-none d-lg-block ${styles.productImg}`} alt="Aufbewahrungstin zum Reisen" />
          </Col>
        </Row>
        <Row className={`${styles.productRow} align-items-center`}>
          <Col lg={6}>
            <GatsbyImage image={data.dropsimglas.childImageSharp.gatsbyImageData} className={`d-none d-lg-block ${styles.productImg}`} alt="62 Drops im Glas" />
          </Col>
          <Col lg={6} className={styles.textSection}>
            <h2><span className={styles.productTitleYellow}>62 Drops im Glas</span></h2>
            <p className={styles.productSubtitle}>Drops Nachfüllglas für einfache Aufbewahrung</p>
            <GatsbyImage image={data.dropsimglas.childImageSharp.gatsbyImageData} className={`d-block d-lg-none ${styles.productImg}`} alt="62 Drops im Glas" />
            <p className={styles.productDescription}>Immer griffbereit. Schickes Glas für die einfache Aufbewahrung und den schnellen Griff zum Drop. Inklusive 62 Drops für einen 1 monatigen Gebrauch.</p>
          </Col>
        </Row>
      </Container>
		</div>
  )
}
